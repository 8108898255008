<app-header [sticky]="true"></app-header>
<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Login</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label class="label-top" for="email">Email</label>
                            <input type="text" class="form-control" formControlName="email" placeholder="example@gmail.com">
                            <div *ngIf="f['email'].touched && f['email'].errors && f['email'].errors['required']" class="form-error">Please fill out this field</div>
                            <div *ngIf="f['email'].touched && f['email'].errors && f['email'].errors['pattern']" class="form-error">Incorrect Format</div>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" class="form-control" formControlName="password" placeholder="Enter your password">
                            <div *ngIf="f['password'].touched && f['password'].errors && f['password'].errors['required']" class="form-error">Please fill out this field</div>
                        </div>
                        <button class="btn submit-btn btn-solid" type="submit">Login</button>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Create A Account</h6>
                    <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                    <button class="btn btn-solid" [routerLink]="['/register']">Create an Account</button>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->
<app-footer></app-footer>