import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/home', title: 'Home', type: 'link' 
		},
		{
			path: '/shop/collection', title: 'Shop', type: 'link'
		},
		{
			title: 'account', type: 'sub', active: false, children: [
				{ path: '/wishlist', title: 'wishlist', type: 'link' },
				{ path: '/cart', title: 'cart', type: 'link' },
				{ path: '/dashboard', title: 'dashboard', type: 'link' },
				{ path: '/forget-password', title: 'forget-password', type: 'link' },
				{ path: '/profile', title: 'profile', type: 'link' },
				{ path: '/checkout', title: 'checkout', type: 'link' },
			]
		},
		{
			path: '/aboutus', title: 'about-us', type: 'link'
		},
		{
			path: '/faq', title: 'FAQ', type: 'link'
		},
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
