import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo-179x34.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = true; // Default false
  
  public stick: boolean = true;
  public isLoggedIn = false;
  public user = null;

  constructor(
    private authService: AuthService,
  ) {
    this.authService.isAuthenticatedUser().subscribe((res) => {
      // console.log("Header login: ", res)
      if (res) {
        this.authService.getCurrentUser().subscribe((user) => {
          this.isLoggedIn = res
          // console.log(user)
          this.user = user
        })
      }
    })   
  }

  ngOnInit(): void {
     
  }

  logout() {
    console.log("Logging out")
    this.authService.logout()
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
