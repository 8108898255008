<app-header [sticky]="true" *ngIf="url != '/pages/comingsoon'"></app-header>
<app-breadcrumb [title]="'Account'" [breadcrumb]="'Account'"></app-breadcrumb>
<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="account-sidebar" (click)="ToggleDashboard()">
                    <a hre="javascript:void(0)" class="popup-btn">
                        my account
                    </a>
                </div>
                <div class="dashboard-left" [class.open]="openDashboard">
                    <div class="collection-mobile-back" (click)="ToggleDashboard()">
                        <span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back
                        </span>
                    </div>
                    <div class="block-content">
                        <ul>
                            <li [class.active]="activeUrl == 'dashboard'"><a routerLink="/account/dashboard">Dashboard</a></li>
                            <li [class.active]="activeUrl == 'profile'"><a routerLink="/account/profile">Account Details</a></li>
                            <li [class.active]="activeUrl == 'address'"><a routerLink="/account/address">Shipping Address</a></li>
                            <li><a routerLink="javascript:void(0)">My Orders</a></li>
                            <!-- <li><a routerLink="/forget-password">Change Password</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>
<!-- section end -->
<app-footer *ngIf="url != '/pages/comingsoon'"></app-footer>
