import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../classes/product';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo-179x34.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();

  public products: Product[] = [];
  public collapse: boolean = true;
  catList: any = []

  constructor(public productService: ProductService) { 
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
    this.productService.categoryGet().subscribe((res) => {
      // console.log("Category: ", res)
      this.catList = res
    });
  }

  get filterbyCategory() {
    return this.catList
  }
}
