<app-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>create account</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="email">First Name</label>
                                <input type="text" class="form-control" formControlName="firstName" placeholder="First Name">
                                <div *ngIf="submitted && f['firstName'].errors && f['firstName'].errors['required']" class="form-error">Please fill out this field</div>
                            </div>
                            <div class="col-md-6">
                                <label for="review">Last Name</label>
                                <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
                                <div *ngIf="submitted && f['lastName'].errors && f['lastName'].errors['required']" class="form-error">Please fill out this field</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="email">email</label>
                                <input type="text" class="form-control" formControlName="email" placeholder="example@gmail.com">
                                <div *ngIf="submitted && f['email'].errors && f['email'].errors['required']" class="form-error">Please fill out this field</div>
                                <div *ngIf="submitted && f['email'].errors && f['email'].errors['email']" class="form-error">Incorrect Format</div>
                            </div>
                            <div class="col-md-6">
                                <label for="email">phone number</label>
                                <input type="number" class="form-control" formControlName="phone" placeholder="081234567890">
                                <div *ngIf="submitted && f['phone'].errors && f['phone'].errors['required']" class="form-error">Please fill out this field</div>
                                <div *ngIf="submitted && f['phone'].errors && f['phone'].errors['pattern']" class="form-error">Incorrect Format</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="review">Password</label>
                                <input type="password" class="form-control" formControlName="password" placeholder="Enter your password">
                                <div *ngIf="submitted && f['password'].errors && f['password'].errors['required']" class="form-error">Please fill out this field</div>
                                <div *ngIf="submitted && registerForm.errors?.passwordMismatchError" class="form-error">Password doesn't match</div>
                            </div>
                            <div class="col-md-6">
                                <label for="review">Confirm Password</label>
                                <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Re-enter your password">
                                <div *ngIf="submitted && f['confirmPassword'].errors && f['confirmPassword'].errors['required']" class="form-error">Please fill out this field</div>
                                <div *ngIf="submitted && registerForm.errors?.passwordMismatchError" class="form-error">Password doesn't match</div>
                            </div>
                        </div>                        
                        <button class="btn btn-solid submit-btn" type="submit">create Account</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->